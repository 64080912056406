/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat

mat-icon.yellow
  color: #ffde33 !important

.mat-mdc-table

  .mat-mdc-header-row
    background: #595959

    .mat-mdc-header-cell
      border-right: 1px solid rgba(255, 255, 255, 0.12)

      &.mat-sort-header[aria-sort='none']:not(:hover)
        .mat-sort-header-arrow
          opacity: .3 !important
          transform: translateY(0px) !important

  .mat-mdc-row:has(.action-buttons .mat-mdc-checkbox-checked)
    background: #555555

  .mat-mdc-row:hover
    background: #666666 !important

.mat-mdc-header-row, .mat-mdc-row:not(.equipment-detail-row)
  height: 20px !important

  .mat-mdc-cell, .mat-mdc-header-cell
    padding: 4px

.spacer
  flex: 1 1 auto

.flex
  display: flex
  align-items: center

.flex-row
  display: flex
  flex-direction: row
  align-items: center

.float-right
  float: right

.disable-text-selection
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

a
  color: inherit

.bookmark a
  cursor: pointer

.header
  margin: 0

.mdc-tooltip
  white-space: pre-line

mat-form-field.no-bottom .mat-mdc-form-field-subscript-wrapper
  display: none

.vertical-align-icon-and-text
  display: flex
  vertical-align: middle
  line-height: 24px

.mat-mdc-form-field-icon-prefix > .mat-icon.small-icon
  padding: 8px

.mat-icon.small-icon
  width: 16px
  height: 16px
  font-size: 16px

.mat-icon.very-small-icon
  width: 12px
  height: 12px
  font-size: 12px

// https://stackoverflow.com/a/57759753
.small-icon-button
  width: 24px !important
  height: 24px !important
  padding: 0 !important
  display: inline-flex !important
  align-items: center
  justify-content: center

  & > *[role=img]
    width: 16px
    height: 16px
    font-size: 16px

    svg
      width: 16px
      height: 16px

  .mat-mdc-button-touch-target
    width: 24px !important
    height: 24px !important

.inline-input
  font: inherit
  background: transparent
  color: #FFFFFF
  border: 0
  margin: 0
  padding: 0
  border-bottom: 1px solid transparent
  width: 100%

  &.ng-dirty
    border-bottom-color: #bdb435 !important

  &:hover, &:focus
    border-bottom-color: #999

  &:focus-visible
    outline: none

.dense-minimum
  @include mat.all-component-densities('minimum')

  &.with-floating-label .mat-mdc-text-field-wrapper .mat-mdc-floating-label
    display: block

.dense-1
  @include mat.all-component-densities(-1)

@media print
  body.mat-app-background app-root,
  .breadcrumb-container .active,
  .mat-mdc-header-cell, .mat-mdc-cell
    color: black !important

  body.mat-app-background, .mat-drawer-container,
  .mat-toolbar.mat-primary,
  .mat-mdc-table, tr.mat-mdc-header-row, tr.mat-mdc-row
    background: transparent !important

  .mat-mdc-card
    --mdc-elevated-card-container-color: transparent

  .mat-toolbar.mat-primary .breadcrumb-container .active
    background: #d5d5d5 !important

  app-global-search, .settings-button, .version, .logo-bw
    display: none !important

  .logo-colored
    display: inline !important

  mat-sidenav-container
    overflow: unset !important

    mat-sidenav, .toggle-button-container
      display: none !important

    mat-sidenav-content
      margin: 0px !important
      padding: 0px !important

  .buttons-container
    button, .selection-label
      visibility: hidden !important

    .filters
      visibility: visible !important

  .equipment-table-container .equipment-container
    overflow: unset !important
    box-shadow: none !important

    .mat-mdc-cell, .mat-mdc-header-cell
      padding: 1px

    .mat-column-actions
      width: auto !important

      mat-checkbox
        display: none
